<div
  class="invoice pointer pl-24 pr-16 py-8"
  [ngClass]="{
    'invoice--operation-column': showOperationColumn,
    'invoice--skeleton': invoice?.prefillStatus === SalesInvoiceAutofillStatus.INITIALISED
  }"
  (mouseenter)="salesInvoiceComponentService.setHover(true)"
  (mouseleave)="salesInvoiceComponentService.setHover(false)"
  (click)="goToInvoice()"
>
  @if (invoice?.prefillStatus !== SalesInvoiceAutofillStatus.INITIALISED) {
    <div class="invoice__title">
      <dougs-invoice-date [invoice]="invoice"></dougs-invoice-date>
      <div class="invoice__title__label">
        <p class="invoice__title__label__reference tiny color-primary-700" *ngIf="!invoice.isDraft">
          {{ invoice.reference }}
        </p>
        <p class="tiny">{{ invoice.label ? invoice.label : '-' }}</p>
      </div>
    </div>
    <p class="tiny color-primary-700">{{ invoice.clientName ? invoice.clientName : '-' }}</p>
    <div *ngIf="showOperationColumn">
      <dougs-pill class="ml-4" *ngIf="invoice?.operationAttachments?.length > 0">
        {{ invoice.operationAttachments.length }}
        <ng-container [ngPlural]="invoice.operationAttachments.length">
          <ng-template ngPluralCase="=1">opération</ng-template>
          <ng-template ngPluralCase="other">opérations</ng-template>
        </ng-container>
      </dougs-pill>
      <dougs-pill
        class="ml-4"
        type="yellow"
        *ngIf="!invoice?.operationAttachments?.length && invoice.operationCandidate"
      >
        <i class="sparkles-icon fal fa-sparkles color-yellow-500 mr-4"></i>
        1 opération
      </dougs-pill>
    </div>
    <div class="text-right">
      @if (invoice.amount || invoice.netAmount) {
        @if (invoice.amount) {
          <p class="tiny color-primary-700 bold">
            {{ invoice?.isRefund ? '-' : '' }}{{ invoice.amount | currency: 'EUR' : 'symbol' : '0.0-2' }}
          </p>
        }
        @if (invoice.netAmount) {
          <p class="tiny">
            {{ invoice?.isRefund ? '-' : '' }}{{ invoice.netAmount | currency: 'EUR' : 'symbol' : '0.0-2' }} HT
          </p>
        }
      } @else {
        <p class="tiny color-primary-700">-</p>
      }
    </div>
    <div class="cta-container">
      <dougs-button
        color="secondary"
        size="small"
        *ngIf="
          invoice.paymentStatus !== SalesInvoicePaymentStatus.PAID &&
          !invoice.isDraft &&
          salesInvoiceComponentService.isHover$()
        "
        [disabled]="salesInvoiceComponentService.isModifying$()"
        [noWrap]="true"
        (click)="salesInvoiceComponentService.markAsPaid($event, invoice)"
      >
        <i class="fal fa-check mr-4"></i>
        Marquer payée
      </dougs-button>
      <dougs-pill *ngIf="invoice.isDraft" type="warning">Brouillon</dougs-pill>
      <dougs-pill *ngIf="invoice.paymentStatus === SalesInvoicePaymentStatus.PAID && !invoice.isDraft" type="success">
        <div class="pill-icon__container color-green-700">
          <i class="fal fa-check pill-icon"></i>
          <p class="xtiny">Payée</p>
        </div>
      </dougs-pill>
      <ng-container *ngIf="!salesInvoiceComponentService.isHover$()">
        <dougs-pill *ngIf="invoice.paymentStatus === SalesInvoicePaymentStatus.LATE && !invoice.isDraft" type="error">
          <div class="pill-icon__container color-red-700">
            <i class="fal fa-alarm-exclamation pill-icon"></i>
            <p class="xtiny">Retard de paiement</p>
          </div>
        </dougs-pill>
        <dougs-pill
          *ngIf="invoice.paymentStatus === SalesInvoicePaymentStatus.WAITING && !invoice.isDraft"
          type="primary"
        >
          <div class="pill-icon__container color-primary-700">
            <i class="fal fa-hourglass-clock pill-icon"></i>
            <p class="xtiny">Attente de paiement</p>
          </div>
        </dougs-pill>
      </ng-container>
    </div>
    <dougs-icon-button
      [dougsDropdownTrigger]="invoiceDropdownActions"
      iconClass="ellipsis-v"
      color="ghost"
      type="regular"
    />
  } @else {
    <div class="sales-invoice-title">
      <div class="sales-invoice-title__spinner__container">
        <i class="sales-invoice-title__spinner fad fa-spinner-third fa-spin fa-lg"></i>
      </div>
      <div class="sales-invoice-title__label">
        <p class="tiny color-primary-700">{{ invoice.reference }}</p>
        <p class="tiny">Récupération des informations de la facture en cours..</p>
      </div>
    </div>
    <div></div>
    <div class="skeleton subtitle"></div>
    <div class="skeleton subtitle"></div>
    <div class="skeleton subtitle"></div>
  }
</div>
<dougs-dropdown [widthToRefElement]="false" #invoiceDropdownActions>
  <dougs-dropdown-option [closeOnClick]="true" *ngIf="invoice.isDraft" (click)="goToInvoice()">
    Modifier
  </dougs-dropdown-option>
  <dougs-dropdown-option
    *ngIf="invoice?.source !== SalesInvoiceSource.FILE_UPLOAD"
    [closeOnClick]="true"
    (click)="duplicateInvoice()"
    >Dupliquer
  </dougs-dropdown-option>
  <dougs-dropdown-option [closeOnClick]="true" (click)="salesInvoiceItemComponentService.downloadPDF(invoice)">
    Télécharger le PDF
  </dougs-dropdown-option>
  <dougs-dropdown-option
    [closeOnClick]="true"
    *ngIf="invoice.isDraft"
    (click)="salesInvoiceDraftComponentService.downloadOffer(invoice)"
  >
    Télécharger la prop. commerciale
  </dougs-dropdown-option>
  <dougs-dropdown-option
    [disabled]="salesInvoiceComponentService.isModifying$()"
    [closeOnClick]="true"
    *ngIf="invoice.paymentStatus === 'paid' && !invoice.isDraft"
    (click)="salesInvoiceComponentService.markAsNotPaid(invoice)"
    [class.gray-background]="salesInvoiceComponentService.isModifying$()"
  >
    Marquer non payée
  </dougs-dropdown-option>
  <dougs-dropdown-option [closeOnClick]="true" *ngIf="invoice.isDraft" (click)="deleteInvoice()">
    <span class="color-error">Supprimer</span>
  </dougs-dropdown-option>
  <dougs-dropdown-option
    [closeOnClick]="true"
    *ngIf="invoice?.source === SalesInvoiceSource.FILE_UPLOAD"
    (click)="salesInvoiceItemComponentService.deleteUploadedFinalizedInvoice(invoice)"
  >
    <span class="color-error">
      <i class="fal fa-trash mr-8"></i>
      Supprimer
    </span>
  </dougs-dropdown-option>
  <!-- Class 'intercom-cancel-invoice' required to trigger an Intercom Custom Bot on click -->
  <dougs-dropdown-option
    [closeOnClick]="true"
    *ngIf="
      !invoice.isDraft &&
      (companyServicesStateService.services$ | async)?.features.hasInvoicingHelp &&
      invoice?.source !== SalesInvoiceSource.FILE_UPLOAD
    "
    class="intercom-cancel-invoice"
  >
    <span class="color-error">Annuler la facture</span>
  </dougs-dropdown-option>
</dougs-dropdown>
